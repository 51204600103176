import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql, Link } from "gatsby"

const Nieuwsberichten = ({ data }) => {
  const posts = data.allMarkdownRemark.edges
  return (
    <Layout>
      <Seo title="Nieuwsberichten" />
      <div className="relative h-219px mt-70px">
        <div className="absolute inset-0 bg-gray opacity-60" />
        <div className="px-4 py-75px flex justify-center flex-col text-center relative">
          <h1 className="font-bold text-white mb-15px text-3xl">
            Nieuwsberichten
          </h1>
        </div>
      </div>
      <section className="max-w-900px mx-auto my-70px px-10 md:px-10">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-5 gap-y-5">
          {posts.map(post => (
            <Link to={`/blog${post.node.fields.slug}`} className="p-5 bg-secondary block cursor-pointer w-full" key={post.node.fields.slug}>
                <GatsbyImage
                  image={
                    post.node.frontmatter.imageCover.childImageSharp
                      .gatsbyImageData
                  }
                  objectFit="fill"
                  alt={post.node.frontmatter.title}
                  className="h-60 w-full mb-15px"
                />
                <h2 className="text-primary text-2xl leading-1.2 mb-5">
                  {post.node.frontmatter.title}
                </h2>
              <p className="text-primary text-lg leading-1.625">
                {post.node.excerpt}
              </p>
            </Link>
          ))}
        </div>
      </section>
    </Layout>
  )
}

export default Nieuwsberichten

export const pageQuery = graphql`
  query BlogList {
    allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { fields: { slug: { ne: "/" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            imageCover {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          excerpt
          fields {
            slug
          }
        }
      }
    }
  }
`
